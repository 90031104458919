<template>
  <v-container fluid style="height: 100%; display: flex; flex-direction: column">
    <page-title title="Leads" subtitle="Browse and Manage your leads!" :badge="this.total" />
    <v-row class="mt-1 mb-1" justify="space-between">
      <v-col sm="12" md="3" cols="12" class="d-flex align-start" style="gap: 0.5rem">
        <v-sheet
          key="sheets-status"
          elevation="2"
          height="32"
          rounded
          class="d-inline-flex align-center justify-center pl-3 pr-1"
        >
          <h4 class="ma-0 text--secondary fs-14px" style="flex: none">Show:</h4>
          <v-btn-toggle
            class="ml-2"
            v-model="options.category"
            mandatory
            color="deep-purple"
            dense
            group
            style="height: 32px"
          >
            <v-btn
              :value="'Active'"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-play mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium"> Active </span>
            </v-btn>
            <v-btn
              :value="'Archived'"
              color="white"
              class="mx-0 mr-1"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-archive mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium"> Archived </span>
            </v-btn>
            <v-btn
              :value="'Converted'"
              color="white"
              class="mx-0"
              style="
                border-radius: 4px;
                padding-left: 0.5rem !important;
                padding-right: 0.5rem !important;
              "
            >
              <i class="fad fa-lightbulb-on mr-2"></i>
              <span style="text-transform: none" class="font-weight-medium">
                Converted to Oppurtunity</span
              >
            </v-btn>
          </v-btn-toggle>
        </v-sheet>
        <v-btn color="info" @click="add()"><i class="fal fa-plus mr-2"></i>New Lead</v-btn>
      </v-col>
      <v-col
        md="9"
        sm="12"
        cols="12"
        class="d-flex flex-row align-center justify-end flex-wrap"
        style="gap: 0.5rem"
      >
        <filter-manager
          ref="filterManager"
          v-model="selectedFilters"
          :options.sync="options"
        ></filter-manager>
        <v-sheet height="28" width="1" color="blue-grey lighten-4" class="mx-1"></v-sheet>
        <columns-visibility-control
          :defaults="defaultHeaders"
          :withColumnsOrder="true"
          v-model="headers"
          :storageKey="storageKey"
        />
        <v-text-field
          v-model="options.search"
          label="Search"
          ref="mainSearch"
          class="table-search-field"
          @focus="mainSearchInFocus = true"
          @blur="mainSearchInFocus = false"
          :style="{
            'max-width':
              mainSearchInFocus || (options.search != '' && options.search != null)
                ? '200px'
                : '110px',
          }"
          dense
          solo
          hide-details
          clearable
          prepend-inner-icon="far fa-search"
          :loading="loadingStates.table"
        >
        </v-text-field>
        <refresh :loading="loadingStates.table" @refresh="getDataDebounced()"></refresh>
      </v-col>
    </v-row>
    <v-data-table
      style="
        overflow: auto;
        overflow: overlay;
        height: 100%;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
      "
      dense
      :headers="tableHeader"
      :items="entities"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loadingStates.table"
      class="elevation-2 leads-table table-sticky-header-exclude-footer"
      id="leadTable"
      @click:row="rowClicked"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'far fa-arrow-to-left',
        lastIcon: 'far fa-arrow-to-right',
        prevIcon: 'far fa-angle-left',
        nextIcon: 'far fa-angle-right',
        itemsPerPageOptions: [15, 30, 50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear color="blue" absolute indeterminate></v-progress-linear>
      </template>

      <template v-slot:[`header.jiraCode`]="{ header }">
        <i :class="header.icon"></i>
        {{ header.text.toUpperCase() }}
      </template>

      <template v-slot:[`item.jiraCode`]="{ item }">
        <a
          v-if="item.jiraCode != null && item.jiraCode != ''"
          target="_blank"
          class="jira-external-link"
          @click.stop
          :href="'https://dangeloconsultants.atlassian.net/browse/' + item.jiraCode"
        >
          <span class="icon-wrpr mr-1">
            <i class="fab fa-jira org-icon"></i>
            <i class="far fa-external-link alt-icon"></i>
          </span>
          <span>{{ item.jiraCode }}</span>
        </a>
        <span v-else class="text--disabled">
          <i class="fad fa-unlink"></i>
        </span>
      </template>

      <template v-slot:[`item.client`]="{ item }">
        <v-tooltip top nudge-top="-16px">
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2" v-bind="attrs" v-on="on" @click.stop="viewClient(item.client.id)">
              <v-img
                v-if="item.client.logoUrl != null && item.client.logoUrl != ''"
                :src="item.client.logoUrl"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
              <v-img
                v-else
                src="/img/DNA_Symbol.png"
                max-height="28"
                max-width="54"
                position="left center"
                contain
              >
              </v-img>
            </div>
          </template>
          <span>{{ item.client.name }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <h4>
          <i v-if="item.isArchived" class="fad fa-archive brown--text mr-2"></i>{{ item.name }}
        </h4>
      </template>

      <template v-slot:[`item.number`]="{ item }">
        <h4>{{ $getTextByValue(item.type, projectTypes) }}{{ item.number }}</h4>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <lead-status :status="item.status" small></lead-status>
      </template>
      <template v-for="elm in HeaderByTypeList" v-slot:[`item.${elm.value}`]="{ item, header }">
        <dater
          v-if="header.type == 'date'"
          :date="item[elm.value]"
          dateonly
          :key="header.type + elm.value"
        ></dater>
        <span v-else-if="header.type == 'usd'" :key="header.type + elm.value">{{
          item[elm.value] | usdFormat
        }}</span>
        <user-avatar
          :key="header.type + elm.value"
          v-else-if="header.type == 'user'"
          :user="item[elm.value]"
          icon
        ></user-avatar>
      </template>

      <template v-slot:[`item.leadComplexity`]="{ item }">
        <span
          class="font-weight-medium"
          :class="item.leadComplexity > 0 ? 'secondary--text' : 'text--disabled'"
        >
          {{ item.leadComplexity }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu dense offset-x right>
          <template v-slot:activator="{ attrs, on }">
            <v-btn icon elevation="0" v-bind="attrs" v-on="on">
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item @click="update(item.id)" v-if="$has(perms.Leads.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>far fa-pen</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="clone(item)" v-if="$has(perms.Leads.Create)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>far fa-clone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Clone</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="archive(item)" v-if="canArchive(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="brown--text">far fa-archive</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="brown--text">Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="unarchive(item)" v-if="canUnarchive(item)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="brown--text">far fa-inbox-out</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="brown--text"> Unarchive </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="my-2"></v-divider>
            <v-list-item @click="del(item)" v-if="$has(perms.Leads.Delete)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small>far fa-trash-alt red--text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Delete</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-subheader
              class="font-weight-bold"
              style="height: auto"
              v-if="!$has(perms.Leads.Update) && !$has(perms.Leads.Delete)"
            >
              No Actions Available!
            </v-subheader>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:no-data>
        <img width="500" src="/img/art/fogg-no-connection-2.png" />
        <p class="font-weight-bold">No Data Available!</p>
      </template>
      <template v-slot:loading>
        <video width="250" muted loop autoplay style="padding: 0.5rem">
          <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
        </video>
        <p class="font-weight-bold">Searching the Cosmos...</p>
      </template>
    </v-data-table>
    <edit-client ref="editClient"></edit-client>
    <edit-lead
      ref="editLead"
      @save="onLeadSaved"
      @clone="onCloneLeadSuccess"
      @delete="onDeleteLeadSuccess"
      @close="onLeadClosed"
    ></edit-lead>
  </v-container>
</template>

<script>
import ApiService from "../services/leads-service.js";
import LeadStatus from "../components/LeadStatus.vue";
import EditLead from "../components/EditLead";
import EditClient from "../../Companies/Clients/components/EditClient.vue";
import Enums from "../../../plugins/enums";
import perms from "../../../plugins/permissions";
import leadHeader from "../config/tables/lead.header";
import FilterManager from "../../Shared/components/FilterManager/FilterManager.vue";
import {
  UserFilterSettings,
  ClientFilterSettings,
  ProjectStatusFilterSettings,
} from "../../Shared/components/FilterManager/FilterSettings";
import enums from "../../../plugins/enums";

export default {
  components: { LeadStatus, EditClient, EditLead, FilterManager },
  data() {
    return {
      perms: perms,
      storageKey: "Leads",
      openLeadInEditMode: false,
      projectTypes: Enums.PROJECT_TYPE_LIST.map((t) => {
        return {
          value: t.value,
          text: t.letter,
        };
      }),
      selectedFilters: [],
      paramId: null,
      entities: [],
      selected: {},
      total: 0,
      leadsTimerId: null,
      valid: false,
      mainSearchInFocus: false,
      options: {
        id: null,
        search: null,
        sortBy: ["updateDate"],
        sortDesc: [true],
        clientIds: [],
        status: [],
        createdByIds: [],
        updatedByIds: [],
        category: "Active",
      },
      modals: {
        update: false,
      },
      loadingStates: {
        table: false,
      },
      defaultHeaders: [],
      headers: leadHeader,
    };
  },
  computed: {
    tableHeader() {
      return this.headers.filter((elm) => !elm.hidden);
    },
    HeaderByTypeList() {
      return this.headers.filter((elm) => elm.type);
    },
  },
  created() {
    this.defaultHeaders = this.headers;
    if (this.$route.query.q) this.options.search = this.$route.query.q;
    if (this.storageKey) {
      this.options.itemsPerPage = +this.$getFromLocal(
        `${this.storageKey}-itemsPerPage`,
        false,
        this.options.itemsPerPage || 15
      );
    }
  },
  mounted() {
    this.initFilterManagerSettings();
    document.querySelector("main.v-main").classList.add("sticky-main-fix");
    //this.$refs.mainSearch.focus();
    this.$moveable(document.querySelector("#leadTable .v-data-table__wrapper"));
    this.checkRouteQuery();
    this.checkForSingleLeadRoute(this.$route);
  },
  beforeDestroy() {
    document.querySelector("main.v-main").classList.remove("sticky-main-fix");
  },
  methods: {
    initFilterManagerSettings() {
      var createdByFilterSettings = new UserFilterSettings();
      createdByFilterSettings.title = "Created By";
      createdByFilterSettings.model = "createdByIds";
      createdByFilterSettings.ref = "createdByFilter";

      var updatedByFilterSettings = new UserFilterSettings();
      updatedByFilterSettings.title = "Updated By";
      updatedByFilterSettings.model = "updatedByIds";
      updatedByFilterSettings.ref = "updatedByFilter";

      var clientFilterSettings = new ClientFilterSettings();

      var projectStatusFilterSettings = new ProjectStatusFilterSettings();
      projectStatusFilterSettings.title = "Lead Status";
      projectStatusFilterSettings.stage = enums.PROJECT_STAGE.Lead;

      this.selectedFilters = [
        createdByFilterSettings,
        updatedByFilterSettings,
        clientFilterSettings,
        projectStatusFilterSettings,
      ];
    },
    clone(lead) {
      this.$dialog
        .info({
          text: `Are you sure you want to clone this lead?<br/><h4>${lead.name}</h4>`,
          title: `Clone Lead?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return ApiService.clone(lead.id)
                  .then((resp) => {
                    this.onCloneLeadSuccess(resp.data);
                    this.$dialog.notify.success("Lead cloned successfully!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error cloning lead!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    canArchive(lead) {
      return !lead.isArchived && this.$has(perms.Leads.Archive);
    },
    canUnarchive(lead) {
      return lead.isArchived && this.$has(perms.Leads.Unarchive);
    },
    archive(lead) {
      this.$dialog
        .info({
          text: `Are you sure you want to Archive this lead?<br/><h4>${lead.name}</h4>`,
          title: `Archive Lead?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return ApiService.archive(lead.id)
                  .then((resp) => {
                    this.getData();
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error archiving lead!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    unarchive(lead) {
      this.$dialog
        .info({
          text: `Are you sure you want to Unarchive this lead?<br/><h4>${lead.name}</h4>`,
          title: `Unarchive Lead?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "info",
              handle: () => {
                return ApiService.unarchive(lead.id)
                  .then((resp) => {
                    this.getData();
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("Error archiving lead!", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    onLeadSaved(leadData, isNewLead) {
      this.$log("onLeadSaved", leadData, isNewLead);
      if (isNewLead) {
        this.addToArr(this.entities, leadData, true);
      } else {
        this.updateArr(this.entities, leadData);
      }
    },
    onLeadClosed() {
      setTimeout(() => {
        const path = `/leads`;
        if (this.$route.path !== path) this.$router.push(path);
        document.title = this.$route.meta.title + " | Concordia";
      });
    },
    viewClient(id) {
      this.$refs.editClient.open(id);
    },
    getDataDebounced() {
      if (this.leadsTimerId == null) {
        this.leadsTimerId = -1;
        this.updateRouterLink();
        this.getData();
        return;
      }
      // cancel pending call
      clearTimeout(this.leadsTimerId);

      // delay new call 400ms
      this.leadsTimerId = setTimeout(() => {
        this.updateRouterLink();
        this.getData();
      }, 400);
    },
    getData() {
      this.$backToTop(0, document.querySelector(".leads-table .v-data-table__wrapper"));
      this.loadingStates.table = true;
      this.$log("getData()", this.$clean(this.options, true));
      ApiService.query(this.$clean(this.options, true))
        .then((resp) => {
          this.entities = resp.data.items;
          this.$log("this.entities", this.entities);
          this.total = resp.data.total;
          this.loadingStates.table = false;
        })
        .catch((err) => {
          this.loadingStates.table = false;
          this.$handleError(err);
        });
    },
    checkRouteQuery(evt) {
      this.$log("checkRouteQuery", this.$route.query.q, evt);
      if (this.$route.query.q) this.options.search = this.$route.query.q;
      else this.options.search = null;
    },
    checkForSingleLeadRoute(route) {
      this.$log("checkForSingleLeadRoute", route);
      if (route.params && route.params.id) {
        this.paramId = Number.parseInt(route.params.id);
        this.openLead(this.paramId);
      } else this.paramId = null;
    },
    updateRouterLink() {
      this.$log("getDataDebounced", this.options.search);
      if (this.$route.query.q == this.options.search) return;
      this.$log("Routing");
      if (this.options.search != null && this.options.search != "") {
        this.$log("PUSH ROUTE", this.options.search);
        this.$router.push({
          path: "/leads",
          query: { q: this.options.search },
          replace: true,
        });
      } else this.$router.push({ path: "/leads", replace: true });
    },
    openLead(id) {
      this.$log("openLead =>>>", id, this.openLeadInEditMode);
      setTimeout(() => {
        this.$refs.editLead.open(id, this.openLeadInEditMode);
        this.openLeadInEditMode = false;
      }, 50);
    },
    add() {
      setTimeout(() => {
        this.$refs.editLead.open(null);
      });
    },
    update(id) {
      this.selected = { id: id };
      this.openLeadInEditMode = true;
      setTimeout(() => {
        const path = `/leads/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    view(id) {
      this.openLeadInEditMode = false;
      setTimeout(() => {
        const path = `/leads/${id}`;
        if (this.$route.path !== path) this.$router.push(path);
      });
    },
    rowClicked(row) {
      this.view(row.id);
    },
    onCloneLeadSuccess(lead) {
      this.entities.unshift(lead);
      setTimeout(() => {
        if (this.$has(perms.Leads.Update)) {
          this.update(lead.id);
        }
      }, 1500);
    },
    onDeleteLeadSuccess(id) {
      const index = this.entities.indexOf(this.entities.filter((e) => e.id == id)[0]);
      if (index > -1) {
        this.entities.splice(index, 1);
      }
    },
    del(lead) {
      this.$dialog
        .warning({
          text: `Are you sure you want to delete this lead?<br/><h4>${lead.name}</h4>`,
          title: `Delete Lead?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.delete(lead.id)
                  .then((resp) => {
                    this.onDeleteLeadSuccess(lead.id);
                    this.$dialog.notify.success("Item deleted successfully", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  })
                  .catch((resp) => {
                    this.$dialog.notify.error("an error occurred during deleting the item", {
                      position: "top-right",
                      timeout: 3000,
                    });
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    options: {
      handler(val) {
        this.$setToLocal(`${this.storageKey}-itemsPerPage`, val.itemsPerPage);
        this.getDataDebounced();
      },
      deep: true,
    },
    $route: {
      handler(newRoute, oldRoute) {
        this.checkRouteQuery();
        this.$log("SPACE TEMPLATES - oldRoute", oldRoute, "newRoute", newRoute);

        //from leads list view TO => single lead view,
        if (oldRoute.name == "leads" && newRoute.name == "lead") {
          this.openLead(newRoute.params.id);
          return;
        }

        //from single lead view TO => single lead view,
        if (oldRoute.name == "lead" && newRoute.name == "lead") {
          this.openLead(newRoute.params.id);
          return;
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.leads-table {
  tbody tr:not(.v-data-table__empty-wrapper) {
    cursor: pointer;
  }
}

.jira-external-link {
  position: relative;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: all 0.2s ease-out;

  span {
    color: rgba($info-base, 1);
  }

  .icon-wrpr {
    width: 12px;
    height: 12px;
  }

  .org-icon {
    position: absolute;
    opacity: 1;
    transform: scale(1) rotate(0deg);
    transition: all 0.2s ease-out;
  }

  .alt-icon {
    position: absolute;
    opacity: 0;
    transform: scale(0) rotate(-180deg);
    transition: all 0.2s ease-out;
  }

  &:focus,
  &:hover {
    background: rgba($info-base, 1);
    span {
      color: #fff;
    }

    .org-icon {
      opacity: 1;
      transform: scale(0) rotate(180deg);
    }

    .alt-icon {
      opacity: 1;
      transform: scale(1) rotate(0deg);
    }
  }
}

.v-list.lead-status-filter {
  .v-list-item:not(:last-child) {
    border-bottom: thin solid rgba($shades-black, 0.1);
  }
}
</style>
