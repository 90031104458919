<template>
  <v-container class="pa-4" v-if="selected != null">
    <v-row>
      <v-col cols="4" v-for="item in budget" :key="item.key">
        <v-text-field-alt
          :key="'f_' + item.key"
          :rules="[allRules.number]"
          :label="item.label"
          :ref="'rf_' + item.key"
          :placeholder="item.label"
          v-model.number="selected[item.key]"
          prefix="$"
          :readonly="readonly"
          @input="updateTotal"
        >
        </v-text-field-alt>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      budget: [
        { label: "Labor", key: "laborBudget" },
        { label: "Materials", key: "materialsBudget" },
        { label: "Total", key: "totalBudget" }
      ],
      selected: null
    };
  },
  props: {
    value: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (!this.isEqual(this.selected, this.value)) {
      this.selected = this.value;
    }
  },
  methods: {
    updateTotal() {
      var labor =
        this.allRules.number(this.selected.laborBudget) != true
          ? 0
          : this.selected.laborBudget;
      var materials =
        this.allRules.number(this.selected.materialsBudget) != true
          ? 0
          : this.selected.materialsBudget;
      this.selected.totalBudget = labor + materials;
    }
  },
  watch: {
    value: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.selected = this.value;
        }
      },
      deep: true
    },
    selected: {
      handler() {
        if (!this.isEqual(this.selected, this.value)) {
          this.$emit("input", this.selected);
        }
      },
      deep: true
    }
  }
};
</script>
